import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../vars/palette";

const StyedArrow = styled(Col)`
  align-content: flex-start;
  display: flex;
  a {
    color: ${colors.green.hex};
    font-size: 2rem;
    position: relative;
    bottom: 0.25rem;
    transition: all 0.3s ease;
    &:hover {
      color: ${colors.darkBlue.hex};
      transition: all 0.3s ease;
    }
  }
`;

export const PaginationNav = ({ route, currentPage, totalPages }) => {
  const toPrev = currentPage > 2 ? `/${currentPage - 1}` : ``;
  const toNext = `/${currentPage + 1}`;
  return (
    <Container>
      <Row
        style={{
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "3vh 0 6vh 0"
        }}>
        <Col xs="auto">
          Page {currentPage} of {totalPages}
        </Col>
        {currentPage > 1 ? (
          <StyedArrow xs="auto">
            <WmkLink label="Go to previous page" to={`${route}${toPrev}`}>
              <HiOutlineChevronLeft />
            </WmkLink>
          </StyedArrow>
        ) : null}
        {currentPage < totalPages ? (
          <StyedArrow xs="auto">
            <WmkLink label="Go to next page" to={`${route}${toNext}`}>
              <HiOutlineChevronRight />
            </WmkLink>
          </StyedArrow>
        ) : null}
      </Row>
    </Container>
  );
};
